<template>
  <v-banner
    v-show="$auth.user() && show"
    transition="slide-y-transition"
    class="please-update-info d-print-none"
    :color="$vuetify.theme.dark ? 'orange darken-4' : 'orange lighten-1'"
    tile app sticky
  >
    <v-icon slot="icon">mdi-progress-alert</v-icon>
    Dear investors, due to the reorganization of the business model, we are stopping all new investments. Invested and deposited amounts will be returned in accordance with the terms of the portal.
    <template #actions>
      <v-btn text plain tile @click="banner = false"><v-icon>mdi-close</v-icon></v-btn>
    </template>
  </v-banner>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "StopInvestingBanner",
  data: () => ({ banner: true }),
  computed: {
    show() {
      if (['Onboarding', 'ResetPasswordConfirm', 'Unsubscribe'].includes(this.$route.name)) {
        return false
      }
      if (this.banner === false) {
        return false
      }
      return true
    },
  },
})
</script>

<style scoped>
.please-update-info {
  margin-bottom: 2px;
}
</style>
