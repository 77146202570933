import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import goTo from 'vuetify/es5/services/goto'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    alias: "/support",
    name: "Login",
    component: () => import("../modules/auth/pages/Login.vue"),
    meta: {
      auth: {
        roles: false,
        redirect: '/',
      },
      verified: false,
    },
  },
  {
    path: "/password-reset/:id/:token",
    name: "ResetPasswordConfirm",
    component: () => import("../modules/auth/pages/ResetPasswordConfirm.vue"),
    meta: { },
  },
  // {
  //   path: "/register",
  //   alias: "/onboarding",
  //   name: "Onboarding",
  //   component: () => import("../modules/auth/pages/OnboardingPage.vue"),
  //   meta: { },
  // },
  {
    path: "/",
    name: "Dashboard",
    component: () => import("../modules/dashboard/pages/Dashboard.vue"),
    meta: { auth: true, verified: true },
  },
  {
    path: '/dashboard/:id',
    name: 'DashboardDetail',
    component: () => import('../modules/dashboard/pages/InvestmentsAllocationDetail.vue'),
    meta: { auth: true, verified: true },
    props(route) { return { id: route.params.id } },
  },
  {
    path: "/account/personal",
    name: "Personal",
    component: () => import("../modules/account/pages/Personal.vue"),
    meta: { auth: true, verified: true  },
  },
  {
    path: "/account/personal/edit",
    name: "EditPersonal",
    component: () => import("../modules/account/pages/PersonalInfoEdit.vue"),
    meta: { auth: true, verified: true  },
  },
  {
    path: "/account/personal/add-new-card",
    name: "AddNewCard",
    component: () => import("../modules/account/pages/AddNewCard.vue"),
    meta: { auth: true, verified: true  },
  },
  {
    path: "/account/company",
    name: "Company",
    component: () => import("../modules/account/pages/Personal.vue"),
    meta: { auth: true, verified: true  },
    props() { return { companyPage: true } },
  },
  {
    path: "/account/companies",
    name: "Companies",
    component: () => import("../modules/companies/pages/Companies.vue"),
    meta: { auth: true, verified: true  },
  },
  {
    path: "/account/companies/create",
    name: "CompanyCreate",
    component: () => import("../modules/companies/pages/CompanyCreate.vue"),
    meta: { auth: true, verified: true  },
  },
  {
    path: "/account/company/edit",
    name: "CompanyEdit",
    component: () => import("../modules/companies/pages/CompanyEdit.vue"),
    meta: { auth: true, verified: true  },
  },
  {
    path: "/account/documents",
    name: "Documents",
    component: () => import("../modules/documents/pages/Documents.vue"),
    meta: { auth: true, verified: true  },
  },
  {
    path: '/agreements/:id',
    name: 'Agreement',
    component: () => import('../modules/documents/pages/AgreementPage.vue'),
    meta: { },
    props(route) { return { id: route.params.id } },
  },
  {
    path: "/account/statements",
    name: "Statements",
    component: () => import("../modules/account/pages/Statements.vue"),
    meta: { auth: true, verified: true  },
  },
  {
    path: "/account/settings",
    name: "Settings",
    component: () => import("../modules/account/pages/Settings.vue"),
    meta: { auth: true, verified: true },
  },
  {
    path: "/account/messages",
    name: "Messages",
    component: () => import("../modules/account/pages/Messages.vue"),
    meta: { auth: true, verified: true  },
  },
  {
    path: "/loans/primary",
    name: "PrimaryMarket",
    component: () => import("../modules/loans/pages/PrimaryMarket.vue"),
    meta: { auth: true, verified: true  },
  },
  {
    path: "/loans/secondary",
    name: "SecondaryMarket",
    component: () => import("../modules/loans/pages/SecondaryMarket.vue"),
    meta: { auth: true, verified: true  },
  },
  {
    path: "/autoinvest",
    name: "AutoInvest",
    component: () => import("../modules/autoinvest/pages/AutoInvest.vue"),
    meta: { auth: true, verified: true  },
  },
  {
    path: "/autoinvest/:type",
    name: "AutoInvestStart",
    component: () => import("../modules/autoinvest/pages/AutoInvestStart.vue"),
    meta: { auth: true, verified: true  },
    props(route) { return { type: route.params.type } },
  },
  {
    path: '/autoinvest-strategy/:id',
    name: 'AutoInvestStrategyDetail',
    component: () => import('../modules/autoinvest/pages/AutoInvestStrategyDetail.vue'),
    meta: { auth: true, verified: true  },
    props(route) { return { id: route.params.id } },
  },
  {
    path: '/autoinvest-strategy/:id/edit',
    name: 'AutoInvestStrategyEditPage',
    component: () => import('../modules/autoinvest/pages/AutoInvestStrategyEditPage.vue'),
    meta: { auth: true, verified: true  },
    props(route) { return { id: route.params.id } },
  },
  {
    path: '/loans/:id',
    name: "Loan",
    component: () => import("../modules/loans/pages/Loan.vue"),
    meta: { auth: true, verified: true  },
    props(route) { return { id: route.params.id } },
  },
  {
    path: '/loan-originators/:id',
    name: 'Loan Originator',
    component: () => import('../modules/loan_originators/pages/LoanOriginatorPage.vue'),
    meta: { },
    props(route) { return { id: route.params.id } },
  },
  {
    path: "/transferfunds/deposit",
    name: "Deposit Funds",
    component: () => import("../modules/transferfunds/pages/DepositFunds.vue"),
    meta: { auth: true, verified: true  },
  },
  {
    path: "/transferfunds/withdrawal",
    name: "Withdrawal Funds",
    component: () => import("../modules/transferfunds/pages/WithdrawalFunds.vue"),
    meta: { auth: true, verified: true  },
  },
  {
    path: "/account/investments",
    name: "MyInvestments",
    component: () => import("../modules/investments/pages/MyInvestments.vue"),
    meta: { auth: true, verified: true  },
  },
  {
    path: '/impersonate/:id',
    name: "Impersonate",
    component: () => import("../modules/auth/pages/Impersonate.vue"),
    meta: { auth: true  },
    props(route) { return { id: route.params.id } },
  },
  {
    path: "/unsubscribe",
    name: "Unsubscribe",
    component: () => import('../modules/auth/pages/Unsubscribe.vue'),
    meta: { },
  },
  {
    path: "/referrals",
    name: "Refer a friend",
    component: () => import("../modules/referrals/pages/ReferFriend.vue"),
    meta: { auth: true, verified: true },
  },
  {
    path: '/:catchAll(.*)',
    name: 'error-404',
    component: () => import('../base/pages/NotFoundPage.vue'),
    meta: { verified: false  },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo: string | number = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
  routes,
})

// This is needed to get the user to the previous page if they were
// logged out or if they were not logged in when they arrived.
let lastRouteNameWithAuth = null

router.beforeEach((to, from, next) => {
  // Open the chat window if user arrived to /support
  if (to.path === '/support') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.$crisp.push(['do', 'chat:open'])
  }

  if (from.name === 'Login' && lastRouteNameWithAuth && to.meta.auth === true) {
    const name = lastRouteNameWithAuth
    lastRouteNameWithAuth = null // this should prevent accidental recursion
    next({ name })
  } else {
    // If params is set (e.g. it was a page for a specific strategy),
    // then we will not do anything.
    if (to.meta.auth === true && !to.params?.id) {
      lastRouteNameWithAuth = to.name
    }
    next()
  }
})

export default router
